export interface tbSubject {
  SubjectID: number | string;
  SubjectName: string;
  SubjectShortName?: string;
  Priority?: number;
}

export interface CourseOutcome {
  CourseOutcomeID: number;
  Code: string;
  Description: string;
  SortOrder: number;
  OutcomeType: string;
}


export interface TbCalculateAttendanceMark {
  PaperAssessmentItemID: number;
  PaperNameID: number;
}



export interface TbSettings {
  SettingsID: string;
  IDPrefix: string;
  IDSuffix: string;
  IDCode: string;
  IsAdmnNo_IDcode?: string;
  IsEmailSend?: string;
  InternalAttendaneID?: number;
  InternalTestID?: number;
  InternalAssignmentID?: number;
  IsTimeTAbleDayWise: string;
  IsSectorwise?: string;
  ApplicationNo?: number;
  AppNoPreffix: string;
  AppNoSuffix: string;
  InstitutionID?: number;
  IsAdmissionAutomatic?: string;
  IsCollectionAutomatic?: string;
  IsReceiptAcademicWise?: string;
  IsReceiptFinYearWise?: string;
  IsReceiptContinous?: string;
  IsEvaluationSeperate?: string;
  MultipleSemyearEvaluation?: string;
  SslcCourseID?: number;
  FatherRelID?: number;
  OpenCourseID?: number;
  EvalPreviousSem?: string;
  AttFromInternalMark?: string;
  DLAttendanceStatus: string;
  IsfreezAllowTeacher?: string;
  IsEnableReAdmission?: string;
  IsEnableAccessPermissionBesedOnLevel?: string;
  IsEnableHODAccessPermission?: string;
  IsEnableTutorAccessPermission?: string;
  EnabledEvaluationType?: string;
  AluminiNo?: number;
  AluminiPreffix: string;
  AluminiSuffix: string;
  EnableAlumniRegistrationOnline?: string;
  ConvertStudentsToAlumniFromTC?: string;
  IsUnfreezAllowTeacher?: string;
  IsStudentEntryAllowed?: string;
  SemYearIDForImport: string;
  IsStudentPasswordRandom?: string;
  IsTeacherProfileAdvanced?: string;
  AddSPClassInPaperwiseAttendance?: string;
  IsAdmissionTemporary?: string;
  TemporaryRegisterNo?: number;
  TemporaryAdmissionStatusID?: number;
  TemporaryAccountPassword: string;
  IsPh_Registerno: string;
  OnlineEnquiry?: string;
}


export interface tbCourse {
  CourseID: number | string;
  CourseName: string;
  CourseShortName?: string;
  Duration?: number;
  TotalSeats?: number;
  Credits?: number;
  DepartmentID?: number;
  CourseStreamCategoryID?: number;
  SectorID?: number;
  SanctionedDate?: string | null;
  StartingDate?: string | null;
  SemesterYearStatus?: number;
  SemesterDuration?: number;
  InstructionMediumID?: number;
  RunningStatus?: number;
  DiscontinuedDate?: string | null;
  Remarks?: string;
  Priority?: number;
  AccHeadID?: number;
  Examconducted?: number;
  OnlineApplicationCutOfPercentage?: number;
  CourseCategory?: string;
  MainSubject?: string;
  InstitutionID?: number;
  LoginUserID?: number;
  DepartmentName?: string;
  CourseStreamName?: string;
  POCnt?: number;
  PSOCnt?: number;
  SyllabusTitle?: string | null;
  SyllabusFileName?: string | null;
  CourseSyllabusID?: number;
  isSelected?: boolean;
  CourseCategoryID?: number;
  StaffID?: number;
}
export interface tbSubjectGroupMaster {
  SubjectGroupID: number | string;
  SubjectGroupName: string;
  InstitutionID?: number;
  SortOrder?: number;
  ShortName?: string;
  StudentPreference?: string;
  PreferenceCount?: number;
  SyllabusPatternID?: number;
  UsedCount?: number;
}
export interface SubjectComponent {
  SubjectComponentID: number | string;
  SubjectComponentName: string;
  InstitutionID?: number;
  SortOrder?: number;
}

export interface LookUpMaster {
  DetailsID?: number;
  MasterItemID?: number;
  Code: number | '%' | string;
  DisplayName: string;
  Description?: string;
  ParentDetailsID?: number;
  IsDefault?: boolean;
  ShortName?: string;
  Priority?: number;
  InstitutionID?: number;
  Remark?: string;
}

export interface tbPaperName {
  PaperNameID: number;
  SubjectGroupID?: number;
  SubjectID?: number;
  SubjectComponentID?: number;
  PaperNo?: string;
  PaperName?: string;
  PaperDescription?: string;
  PaperCode?: string;
  MarkOrGrade?: string;
  Maximum?: string;
  Priority?: number;
  Minimum?: string;
  InstitutionID?: number;
  NomAverage?: number;
  Credits?: number;
  shortname?: string;
  CourseCategoryID?: number;
  IsActive?: string;
  SemesterID?: number;
  RegularOrPrivateStatus?: string;
  AttendanceCalcType?: string;
  SemesterName?: string;
  CategoryName?: string;
  COCnt?: number;
  CSOCnt?: number;
  CourseExamQuestionPaperID?: number;
  LoginUserID: number;
  StudentRegCount?: number;
  PaperUsed?: number;
  SyllabusPatternID?: number;
  CourseLevelID?: number;
  GroupNameID?: number;
  RestrictedMajorSubjectID?: number;
  TotalSeat?: number;
  AssessmentTemplateMasterID?:number
}

export interface tbPaperOutcome {
  PaperOutcomeID: number;
  Code: string;
  Description: string;
  SortOrder: number;
  OutcomeType: string;
  FromDate: string;
  ToDate: string;
  CoursePlanDetails?: CourseModuleDetails[]
}

export interface CourseModuleDetails {
  SubjectPlannerID: number;
  PaperID: number;
  ModuleID: number;
  Topic: string;
  Status: string;
}


export interface SemesterMaster {
  SemesterYearID: number | string;
  SemesterName: string;
  SortOrder?: number;
  ShortName?: string;
  UserID?: string;
  isSelected?: boolean;
}

export interface tbCourseSyllabus {
  CourseSyllabusID: number;
  CourseID: number;
  Title: string;
  FilePath: string;
  AcademicYearID: number;
  LoginUserID: number;
}
export interface tbPapersInClass {
  PapersInClassID: number;
  CourseSemesterYearID: number;
  PaperNameID: number;
  PeriodsPerWeek: number;
  Priority: number;
  NomAverage: number;
  Credits: number;
  IsBatchAllowed: string;
  SyllabusID?: number;
  CourseID?: number;
  SemesterID?: number;
  AcademicYearID?: number;
  PaperName?: string;
  Staff?: string;
  Batch?: string;
  RegCount?: number;
  SubjectGroupName?: string;
  PaperCode?: string;
  PaperDescription?: string;
  DepartmentID?: number;
  IncludeInRanking: string;
  IncludeInProgressReport: string;
  MultipleStaff: string;
  IsMaxCountReach: string;
  StaffIDs: string;
  isSelected?: boolean;
}

export interface StudentRegPapers {
  SemesterID: string;
  StaffName: string;

  PaperName: string;
  SemesteryearStudentID: number;
  PaperDescription: string;
  PaperNameID: number;
  Credits: number;
  PaperCode: string;
  CourseCategory: string;
}

export interface tbAcademicYear {
  AcademicYearID: number;
  InstitutionID: number;
  FromDate: string;
  ToDate: string;
  AcademicYear: string;
  YearClosed: string;
  UserID: string;
}

export interface InternalAssessmentItems {
  AssessmentItemID: number;
  AssessmentItem: string;
  ShortName: string;
  Maximum: number | null;
  Minimum: number | null;
  Priority: number | null;
  NomAverage: number | null;
  Weightage: number | null;
  ConvMark: number | null;
  EnableMarkSettigs: string;
  SyllabusPatternID?: number
  AssessmentGroupID?: number
  // checked: boolean;
}
// public class tbPaperAssessmentItems
//     {
//         public int PaperAssessmentItemID { get; set; }
//         public int? CourseSemesterYearID { get; set; }
//         public short? AssessmentItemID { get; set; }
//         public short? PaperNameID { get; set; }
//         public float? Maximum { get; set; }
//         public float? Minimum { get; set; }
//         public int? Duration { get; set; }
//         public short? Priority { get; set; }
//         public float? NomAverage { get; set; }
//         public float? ConvMark { get; set; }
//         public string IsEntryClosed { get; set; }
//         public string PaperDescription { get; set; }
//         public string AssessmentItem { get; set; }
//     }
export interface tbPaperAssessmentItems {
  PaperAssessmentItemID: number;
  CourseSemesterYearID: number;
  AssessmentItemID: number;
  PaperNameID: number;
  Maximum: number | null;
  Minimum: number | null;
  Duration: number | null;
  Priority: number | null;
  NomAverage: number | null;
  ConvMark: number | null;
  IsEntryClosed: string;
  PaperDescription: string;
  AssessmentItem: string;
}


export interface dummy {

  PaperCode: number | null;
  PaperName: string;
  MaxMark: number | null;
  MinMark: number | null;
}

// export interface StudentRegPapers {
//   semesteryearStudentID: number;
//   paperDescription: string;
//   paperNameID: number;
//   courseCategory: string;
//   paperCode: string;
//   credits: number;
// }
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  children?: PeriodicElement[]; // Define a children property for nested data
}



export interface CourseAssessmentItems {
  ClassDescription: string;
  PaperDescription: string;
  Maximum?: number;
  Minimum?: number;
  AssessmentItemID?: number;
  PaperAssessmentItemID: string;
  CourseSemesterYearID?: number;
  PaperNameID?: number;
  selected?: boolean;
  CourseAssesmentMarkSettings?: tbCourseAssesmentMarkSettings[];
  checked?: boolean;
}


export interface tbCourseAssesmentMarkSettings {
  SettingID?: number;
  PaperAssessmentItemID?: string;
  PercentageFrom?: number;
  PercentageTo?: number;
  selected?: boolean;
  Mark?: number;
  Grade?: string;
  MarkOrGrade?: string;
  Gender?: string;
  Select?: boolean;
}

export interface DialogData {
  paperAssessmentItemIDs: string[];
}


export interface testing {
  // other properties...
  AssessmentItemID: number; // make it non-optional
  SettingID: number; // make it non-optional
  // other properties...
}



export interface ClassDetailsByCourse {
  ClassDescription: string;
  CourseSemesterYearID: number;
  StudentRegCount?: number;
  Assessment: AssessmentDetails[];
}

export interface AssessmentDetails {
  PaperAssessmentItemID: number;
  AssessmentItemID: number;
  AssessmentItem: string;
  CourseSemesterYearID: number;
  ClassDescription: string;
}

export interface CourseCredit {
  CourseCreditID: number;
  CourseID: number;
  AcademicPathwayID: number;
  CourseTypeID: number;
  TotalCredit: number;
  CourseCount: number;
  CourseCreditDetailID: number;
  SemesterYearID: number;
  Status: string;
  InDepartment: string;
  ConsiderCourseType: string;
  Credit: number;
  MaxCount: number;
  Remark: string;
  SubjectGroupName: string;
  DisplayName: string;
  CourseName: string;
  SemesterName: string;
  SyllabusPatternID: number;
  CourseIDs: string;
  HrsPerWeek: number;
  selected?: boolean;
  CourseCreditDetailsData: CourseCreditDetails[];
}

export interface CourseCreditDetails {
  CourseCreditID: number;
  CourseID: number;
  AcademicPathwayID: number;
  CourseTypeID: number;
  TotalCredit: number;
  CourseCount: number;
  CourseCreditDetailID: number;
  SemesterYearID: number;
  Status: string;
  InDepartment: string;
  ConsiderCourseType: string;
  Credit: number;
  MaxCount: number;
  Remark: string;
  SubjectGroupName: string;
  DisplayName: string;
  CourseName: string;
  SemesterName: string;
  SyllabusPatternID: number;
  HrsPerWeek: number;
}

export interface SubjectGroupMaster {
  SubjectGroupID: number;
  SubjectGroupName: string;
  ShortName: string;
  SyllabusPatternID: number;
  InstitutionID: number;
}

export interface ClassDetailForPromotion {
  OldCount: number;
  NewCount: number;
  ClassDescription: string;
  CourseSemesterYearID: number;
  NewClassDescription: string;
  NewCourseSemesterYearID: number;
}

export interface AcademicYear {
  AcademicYearID: number;
  InstitutionID: number;
  FromDate: string;
  ToDate: string;
  AcademicYear: string;
  YearClosed: string;
  UserID: string;
}